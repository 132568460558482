import React, { useState } from 'react';
import './ForgetScreen.css';
import axios from 'axios';

import Loading from '../../Components/Loading/Loading';

import classes from './newForgetScreen.module.css'
import { NewHeader } from '../../Components';
import { FiMail } from 'react-icons/fi';

const key = 'AIzaSyAKreen5ywTxcGvxmLXAhZhFSx0u5L5N_s';
const url = 'https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=' + key

function ForgetScreen(props) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const [done, setDone]  = useState();
    const [email, setEmail] = useState()

    const sendVerification = () => {
        setLoading(true);
        setError(false);
        const data = {
            requestType: 'PASSWORD_RESET',
            email: email
        }
        axios.post(url, data)
            .then(r => {
                console.log(r)
                setDone(true)
                setLoading(false)
            })
            .catch(e => {
                let errorM = 'Network Error'
                if(e.response){
                    errorM = e.response.data.error.message
                }
                setError(errorM)
                setLoading(false)
            })
    }

    let container = (
        <div className={classes.doneContainer}>
            <h3>Reset Password</h3>
            <p>Enter email associated with this account</p>
            <input placeholder='Email' onChange={(e) => setEmail(e.target.value)}/>
            
            <div className={classes.buttonsContainer}>
                <button className={classes.reset} onClick={sendVerification}>
                    <p>Reset</p>
                </button>
                <button className={classes.backtologin} onClick={() => props.history.push('/signin')}>
                    Back to login
                </button>
            </div>
            
            {error &&
                <div className='ForgetPasswordErrorBox'>
                    <p>{error}</p>
                </div>
            }
        </div>
    )

    if(done){
        container = (
            <div className={classes.doneContainer}>
                <div className={classes.check}> <FiMail /> </div>
                <h3>Reset Password</h3>
                <p>A reset password link has been sent to your Email, please reset password and sign in</p>
                <button className={classes.backtologin} onClick={() => props.history.push('/signin')}>
                    Back to login
                </button>
                {error &&
                    <div className='ForgetPasswordErrorBox'>
                        <p>{error}</p>
                    </div>
                }
            </div>
        )
    }

    return (
        <div className={classes.container}>
            <NewHeader />
            <div className={classes.mainContainer}>
                <div>
                    {!loading ? container: <Loading />}
                </div>
                <div className={classes.bannerImg} style={{backgroundImage: `url(https://res.cloudinary.com/ahumareze/image/upload/v1688825413/03_dhawwp.jpg)`}} />
            </div>
        </div>
    );
}

export default ForgetScreen;