import React, { useEffect, useState } from 'react';

//styles
import classes from './uploadpicture.module.css';

import axios from 'axios';
import { Hoc } from '../../Components';
import man from '../../Assets/man.png';
import Loading from '../../Components/Loading/Loading';
import { FiMail, FiMapPin, FiPenTool, FiPhone, FiUser } from 'react-icons/fi';
import { BsFillPencilFill } from 'react-icons/bs';

function UploadPicture(props) {
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getEmail();
    }, [])

    const getEmail = () => {
        const data = localStorage.getItem('@localemail');
        if(data){
            fetchUser(data)
        }
    }

    const fetchUser = (email) => {
        setLoading(true)
        axios.get('https://elite-corp-default-rtdb.firebaseio.com/users.json')
            .then(r => {
                const data = r.data;
                let arr = {};
                Object.keys(data).map((key, i) => {
                    if(data[key].email === email){
                        arr = {...data[key], key: key}
                        console.log(arr)
                        setUser(arr);
                        localStorage.setItem('@key', key)
                        localStorage.setItem('@localUsername', data[key].fullname)
                    }
                });
                setLoading(false)
                // console.log(r)
            })
            .catch(e => {
                setLoading(false);
                console.log(e)
            })
    }

    const postImage = (img) => {
        const formData = new FormData()
        formData.append('file', img);
        formData.append('upload_preset', 'kqiuojxc');
        setLoading(true)

        axios.post('https://api.cloudinary.com/v1_1/ahumareze/image/upload', formData)
            .then(r => {
                // console.log(r.data.url, user.key)
                postDatabase(r.data.url);
                setUser({...user, profilePic: r.data.url});
                setLoading(false)
            })
            .catch(e => {
                setLoading(false)
            })

    };

    const postDatabase = (url) => {
        const datae = {
            ...user,
            profilePic: url
        }
        axios.put('https://elite-corp-default-rtdb.firebaseio.com/users/' + user.key + '.json', datae )
            .then(r => console.log(r.data))
            .catch(e => {
                // console.log(typeof(e));
                // setErrorMessage(e)
            })
    }

    return (
        <Hoc user={user} active={"Profile"} history={props.history} isProfile >
            {loading ? 
                <Loading /> 
            :
                <div>
                    <img className={classes.banner} />
                    <div className={classes.main}>
                        <img className={classes.profilePicture} src={user?.profilePic ? user.profilePic : man} />
                        <div className={classes.editContainer}>
                            <label htmlFor='file-input'>
                                <div className={classes.editButton}>Upload Photo</div>
                                <input id='file-input' accept="image/png, image/gif, image/jpeg" type='file' onChange={(e) => postImage(e.target.files[0])} />
                            </label>
                        </div>
                        {/* <div className={classes.details}>
                            <h3>{user?.fullname}</h3>
                            <p>{user?.email}</p>
                        </div> */}
                        <div className={classes.listContainer}>
                            <div className={classes.listItem}>
                                <div className={classes.itemTitle}>
                                    <div> <FiUser />  </div><p>Fullname: </p>
                                </div>
                                <div className={classes.itemDetails}> {user?.fullname} </div>
                            </div>
                            <div className={classes.listItem}>
                                <div className={classes.itemTitle}>
                                    <div style={{backgroundColor: 'grey'}}> <FiMail />  </div><p>Email: </p>
                                </div>
                                <div className={classes.itemDetails}> {user?.email} </div>
                            </div>
                            <div className={classes.listItem}>
                                <div className={classes.itemTitle}>
                                    <div style={{backgroundColor: 'cyan'}}> <FiPhone />  </div><p>Phone Number: </p>
                                </div>
                                <div className={classes.itemDetails}> {user?.phone} </div>
                            </div>
                            <div className={classes.listItem}>
                                <div className={classes.itemTitle}>
                                    <div style={{backgroundColor: 'red'}}> <FiMapPin />  </div><p>Country: </p>
                                </div>
                                <div className={classes.itemDetails}> {user?.country} </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Hoc>
    )
}

export default UploadPicture