import React from 'react';

//styles
import classes from './plans.module.css';
import { AiFillCheckCircle } from 'react-icons/ai';

function Plans() {

    const ListItem = ({text}) => {
        return(
            <div className={classes.listItem}>
                <AiFillCheckCircle className={classes.check} size={50} />
                <p>{text}</p>
            </div>
        )
    }

    const Item = ({icon, title, amount, features}) => {
        return(
            <div className={classes.item} data-aos="fade-up">
                <div className={classes.image}>{icon}</div>
                <h3 className={classes.title}>{title}</h3>
                <h3 className={classes.amount}>{amount}</h3>
                <div className={classes.list}>
                    {features.map((i, idx) => (
                        <ListItem text={i} key={idx} />
                    ))}
                </div>
                <a href='/signin'><button>Get Started</button></a>
            </div>
        )
    }

    return (
        <div className={classes.container}>
            <h2 data-aos="fade-up">Investment Packages</h2>
            <p className={classes.introText} data-aos="fade-up">Below are the available investment plans we have for our amiable clients to partake in</p>
            <div className={classes.plansContainer}>
                <Item
                    icon={'🔥'}
                    title={'Basic'}
                    amount={'$1,000'}
                    features={[
                        '24HRS Increment',
                        'Variable Mining Power',
                        'Shared Cloud Mining',
                        'On Weekly ROI Model: 7% Weekly',
                        'Two Weeks Duration',
                        '3% Referral Commission',
                        'Minimum Investment $1,000',
                        'Maximum Investment $4,999'
                    ]}
                />
                <Item
                    icon={'🚀'}
                    title={'Advanced'}
                    amount={'$5,000'}
                    features={[
                        '24HRS Increment',
                        'Variable Mining Power',
                        'Shared Cloud Mining',
                        'On Weekly ROI Model: 12% Weekly ',
                        'Three Weeks Duration',
                        '6% Referral Commission',
                        'Minimum Investment $5,000',
                        'Maximum Investment $9,999'
                    ]}
                />
                <Item
                    icon={'⚡️'}
                    title={'Pro'}
                    amount={'$10,000'}
                    features={[
                        '24HRS Increment',
                        'Variable Mining Power',
                        'Shared Cloud Mining',
                        'On Weekly ROI Model: 17% Weekly ',
                        'Four Weeks Duration',
                        '9% Referral Commission',
                        'Minimum Investment $10,000',
                        'Maximum Investment $49,999'
                    ]}
                />
                <Item
                    icon={'💎'}
                    title={'Ultra'}
                    amount={'$50,000'}
                    features={[
                        '24HRS Increment',
                        'Variable Mining Power',
                        'Shared Cloud Mining',
                        'On Weekly ROI Model: 22% Weekly ',
                        'Five Weeks Duration',
                        '12% Referral Commission',
                        'Minimum Investment $50,000',
                        'Maximum Investment Unlimited'
                    ]}
                />
            </div>
        </div>
    )
}

export default Plans