import React from 'react';
import WithItem from './WithItem';
import classes from './style.module.css';
import empty_coins from '../../Assets/empty_coins.png';
import atm from '../../Assets/atm.png';

function WithContainer({transaction, select}) {

    let view = (
        <div className={classes.emptyDeposits}>
            <img src={atm} alt='' />
            <h3>You haven't made any withdrawal yet</h3>
            <p>No withdrawal request has been placed on this acccount, visit the above modal and start processing your withdrawal request</p>
        </div>
    )
    if(transaction)(
        view = (
            <>
                {Object.keys(transaction).reverse().map(i => (
                    <div className='DepositHistoryItem' onClick={() => select(transaction[i])}>
                        <p>{transaction[i].coinname} </p>
                        <p className='DepoH_amount' >${transaction[i].amount}</p>
                        <p>{transaction[i].network}</p>
                        <p className='DepoH_status'>{transaction[i].walletName}</p>
                        <p>{transaction[i].status}</p>
                    </div>
                    // <WithItem 
                    //     coinname={transaction[i].coinname} 
                    //     amount={transaction[i].amount} 
                    //     walletName={transaction[i].walletName} 
                    //     walletId={transaction[i].wallet}
                    //     network={transaction[i].network}
                    //     status={transaction[i].status}
                    //     date={transaction[i].date}
                    // />
                ))}
            </>
        )
    )
    
    return (
        <div className='DepositHistory'>
            <h2>Withdrawals</h2>
            <p className='dep_history_extra'>All withdrawals will appear here after minimum confirmations. Select a transaction to view more details.</p>
            <div className='DepositHistoryHeader'>
                <p>Coin</p>
                <p>Amount</p>
                <p>Network</p>
                <p className='DepoH_status'>Wallet</p>
                <p>Status</p>
            </div>
            {view}
        </div>
    );
}

export default WithContainer;