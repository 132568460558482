import React from 'react';

//styles
import classes from './partners.module.css';

//images
import binance from '../../../Assets/partners/binance.svg';
import metamask from '../../../Assets/partners/metamask.svg';
import kucoin from '../../../Assets/partners/kucoin.svg';
import shakepay from '../../../Assets/partners/shakepay.svg';
import trustwallet from '../../../Assets/partners/trustwallet.svg';
import coinbase from '../../../Assets/partners/coinbase.svg';

const imgArr = [binance, coinbase, trustwallet, shakepay, metamask, kucoin]

function Partners() {
    return (
        <div className={classes.container} data-aos="fade-up">
            <h2>Our Partners</h2>
            <div className={classes.main}>
                {imgArr.map((i, idx) => (
                    <img src={i} key={idx} alt='' />
                ))}
            </div>
        </div>
    )
}

export default Partners