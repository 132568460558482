import React, {useState} from 'react';
import InputBox from './InputBox';
import classes from './depositbox.module.css';

const width = window.innerWidth;

function DepositBox(props) {

    const [coinname, setCoinName] = useState("");
    const [amount, setAmount] = useState("");
    const [wallet, setwallet] = useState("");
    const [recievingId, setRecievingId] = useState("");
    const [network, setNetwork] = useState("")

    //UI state
    const [errorMessage, setErrorMessage] = useState(null);
    
    const onClick = () => {
        if(coinname && amount && wallet && recievingId && network){
            const data = {
                coinname,
                amount,
                wallet,
                recievingId,
                network
            }
            props.onClick(data)
            setErrorMessage(null)
        }else{
            setErrorMessage("please fill in fields")
        }
        
    }
    return (
        <div className={classes.container}>
            <div className={classes.top}>
                <div className={classes.icon}> {props.icon} </div>
                <p className={classes.boxTitle}>{props.title}</p>
            </div>
            <div className={classes.inputContainer}>
                <InputBox name={props.boxes[0]} color='yellow' change={e => setCoinName(e)} />
                <div className={classes.flexInputs}>
                    <InputBox name={props.boxes[1]} number color='yellow' change={e => setAmount(e)} />
                    <InputBox name={props.boxes[2]} color='yellow' change={e => setwallet(e)} />
                </div>
                
                <InputBox name={props.boxes[3]} color='yellow' change={e => setRecievingId(e)} />
                <select className={classes.selectBox} onChange={(e) => setNetwork(e.target.value)} >
                    <option disabled selected hidden >Network</option>
                    <option value='Bitcoin' >Bitcoin</option>
                    <option value='Binance Smart Chain' >Binance Smart Chain (BEP20)</option>
                    <option value='Ethereum' >Ethereum (ERC20)</option>
                    <option value={'Solana'}>Solana</option>
                    <option value={'Binance Chain'} >BNB Beacon Chain (BEP2)</option>
                    <option value={'Tron'}>Tron (TRC20)</option>
                </select>
                <select className={classes.selectBox} >
                    <option disabled selected hidden value='Ethereum' >Plan</option>
                    <option value='Ethereum' >Basic</option>
                    <option value={'Solana'}>Advanced</option>
                    <option value={'Binance Chain'} >Pro</option>
                    <option value={'Tron'}>Ultra</option>
                </select>
            </div>
            {props.errorMessage ? <p className='CF_error'>{props.errorMessage}</p> : null}
            {errorMessage && <p className='CF_error'>{errorMessage}</p>  }
            {props.extraMessage ? <p style={{marginTop: 20, fontWeight: 600, textAlign: 'justify'}}>{props.extraMessage}</p> : null}
            <div className={classes.submit} onClick={onClick}> { !props.loading ? props.button : 'loading...'} </div>
        </div>
    );
}

export default DepositBox;