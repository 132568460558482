import React from 'react';
import { FiCheckCircle, FiClock, FiX, FiXCircle } from 'react-icons/fi';

function OpenWithdrawal({data, close}) {

    let status;

    if(data.status == 'Approved' || data.status == 'approved'){
        status = (
            <div className='sdm_status'>
                <FiCheckCircle />
                <p>Approved</p>
            </div>
        )
    }else if(data.status == 'Pending' || data.status == 'pending' ){
        status = (
            <div className='sdm_status2'>
                <FiClock />
                <p>Pending</p>
            </div>
        )
    }else if(data.status === 'Declined' || data.status === 'declined'){
        status = (
            <div className='sdm_status3'>
                <FiXCircle />
                <p>Declined</p>
            </div>
        )
    };

    return (
        <div className='ShowDepositScreen'>
            <div className='showDepCancle'>
                <FiX onClick={close} />
            </div>
            <div className='mainShowDeposit'>
                <div className='mainShowDepositTop'>
                    <p className='showDepTitle'>Withdrawal Details</p>

                    <div className='showDepMain'>
                        <p className='sdm_1'>Amount</p>
                        <p className='sdm_2'>{data.amount}<span>USD</span></p>
                        {status}
                    </div>
                    <p className='sdm_extraStatus'>Crypto transferred out of your Vertexcorp account. Please contact the recipient platform for your transaction reciept if approved.</p>
                </div>

                <div className='sdm_mainDetails'>
                <div className='sdm_item'>
                        <div className='sdm_item_1'>Coin name</div>
                        <div className='sdm_item_2'>{data.coinname}</div>
                    </div>
                    <div className='sdm_item'>
                        <div className='sdm_item_1'>Network</div>
                        <div className='sdm_item_2'>{data.network}</div>
                    </div>
                    <div className='sdm_item'>
                        <div className='sdm_item_1'>Address</div>
                        <div className='sdm_item_2'>{data.wallet}</div>
                    </div>
                    <div className='sdm_item'>
                        <div className='sdm_item_1'>Wallet Name</div>
                        <div className='sdm_item_2'>{data.walletName}</div>
                    </div>
                    <div className='sdm_item'>
                        <div className='sdm_item_1'>Status</div>
                        <div className='sdm_item_2 sdm_capitialize'>{data.status}</div>
                    </div>
                    <div className='sdm_item'>
                        <div className='sdm_item_1'>Date</div>
                        <div className='sdm_item_2'><p>{data.date}</p></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OpenWithdrawal;