import React, { useEffect, useState } from 'react';

//styles
import classes from './banner.module.css';

//components
import { NewHeader } from '../../../Components';

//aos
// import AOS from 'aos';
// import 'aos/dist/aos.css';

//import images
import img1 from '../../../Assets/banner/img1.svg';
import img2 from '../../../Assets/banner/img2.svg';
import img3 from '../../../Assets/banner/img3.svg';
import Transactions from './transactions/Transactions';

//images
const user1 = 'https://res.cloudinary.com/ahumareze/image/upload/v1686582015/d8f5f01b-063b-49cc-8a1c-eac347e29cb1_hph5lx.jpg';
const user2 = 'https://res.cloudinary.com/ahumareze/image/upload/v1686582014/af5d3801-714c-4882-8a22-21583d1d4816_mhnkt8.jpg'
const user3 = 'https://res.cloudinary.com/ahumareze/image/upload/v1686582014/fa1aa61e-6bd9-43df-a067-bbdf84776d60_pf2ldu.jpg'

const usa = 'https://upload.wikimedia.org/wikipedia/commons/d/de/Flag_of_the_United_States.png';
const germany = 'https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Flag_of_Germany.svg/2560px-Flag_of_Germany.svg.png';
const uk = 'https://upload.wikimedia.org/wikipedia/commons/4/42/Flag_of_the_United_Kingdom.png';

const userArr = [user1, user2, user3];
const countryArr = [usa, germany, uk]

function Banner() {
    const [isSignedIn, setIsSignedIn] = useState(false)

    useEffect(() => {
        const token = localStorage.getItem('@localToken')
        if(token){
            setIsSignedIn(true)
        }
    }, []);

    const Item = ({images, amount, title}) => {
        return(
            <div className={classes.item}>
                <div className={classes.imagesContainer}>
                    {images.map((i, idx) => (
                        <img src={i} key={idx} alt='' />
                    ))}
                </div>
                <h3>{amount} <span>{title}</span></h3>
            </div>
        )
    }

    return (
        <div className={classes.container}>
            <NewHeader />
            <div className={classes.mainContainer} data-aos="fade-up">
                <div className={classes.contents}>
                    <h1>Taking Passive Income to the Next level</h1>
                    <p>The best way to passive income, we are creating a business opportunity for investors who wishes to maximize digital currency and earn passively with little or no effort, and also aid our investors make their livestyle worth living without any left-out debt.</p>
                    <a href='/signin'><button>{isSignedIn ? "View Portfolio" : "Create Account"}</button></a>
                    <div className={classes.moreInfo}>
                        <Item images={userArr} title={'users'} amount={'81k+'} />
                        <Item images={countryArr} title={'countries'} amount={'All'} />
                    </div>
                </div>
                {/* <div className={classes.display_images} data-aos="fade-up">
                    <img src={img1} alt='' />
                    <img src={img3} alt='' />
                    <img src={img2} alt='' />
                </div> */}
                <div className={classes.desktopTransactions}>
                    <Transactions />
                </div>
            </div>
        </div>
    )
}

export default Banner