import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../Redux/Actions/Index';

//styles
import classes from './hoc.module.css';

//images
import updatedLogo from '../../Assets/updated-logo.png';
import man from '../../Assets/man.png';

//icons
import { MdOutlineSpaceDashboard } from 'react-icons/md';
import { FiChevronDown, FiChevronUp, FiHome, FiLogOut, FiMenu, FiUsers, FiX } from 'react-icons/fi';
import { AiTwotoneBank } from 'react-icons/ai';
import { BsBank, BsBriefcase, BsCashStack, BsFillPeopleFill, BsWallet } from 'react-icons/bs';
import { FaParachuteBox } from 'react-icons/fa';

function Hoc(props) {
    //UI state
    const [openMobileDrawer, setOpenMobileDrawer] = useState(false);
    const [showPlans, setShowPlans] = useState(false);

    const logout = () => {
        props.logout()
    }

    const navigate = (link) => {
        props.history.push(link)
    }

    const Link = ({icon, text, path}) => {
        return(
            <div className={text === props.active ? classes.activeLink : classes.link} onClick={() => navigate(path)} >
                {icon}
                {text}
            </div>
        )
    }

    const LinkDropDown = ({icon, text}) => {
        return(
            <div className={classes.LinkDropDown} onClick={() => setShowPlans(prev => !prev)}>
                {icon}
                {text}
                <div className={classes.dropDownIcon}>{showPlans ? <FiChevronUp /> : <FiChevronDown />} </div>
            </div>
        )
    }

    const SideDrawer = () => {
        return(
            <div className={classes.sideDrawer}>
                <div className={classes.logo} onClick={() => navigate('/')} > <img src={updatedLogo} alt='' /> Vertex Corp Investment </div>
                {props.user ?
                    <div className={classes.user}>
                        <img src={props.user?.profilePic ? props.user.profilePic : man}  alt='' />
                        <h4>{props.user.fullname}</h4>
                    </div>
                    :
                    <div className={classes.user}>
                        <img src={man} alt='' />
                        <h4>Loading...</h4>
                    </div>
                }
                <div className={classes.linksContainer}>
                    <Link icon={ <MdOutlineSpaceDashboard size={20} /> } text={'Portfolio'} path='portfolio' />
                    <LinkDropDown icon={ <AiTwotoneBank size={20} /> } text={'All Plans'} />
                    {showPlans &&
                        <div className={classes.plansLink}>
                            <Link icon={ <BsBank size={20} /> } text={'Plan 1'} path='plan1' />
                            <Link icon={ <BsBank size={20} /> } text={'Plan 2'} path='plan2' />
                            <Link icon={ <BsBank size={20} /> } text={'Plan 3'} path='plan3' />
                            <Link icon={ <BsBank size={20} /> } text={'Plan 4'} path='plan4' />
                        </div>
                    }
                    <Link icon={ <BsBriefcase size={20} /> } text={'Deposit'} path='deposit' />
                    <Link icon={ <BsCashStack size={20} /> } text={'Withdraw Funds'} path='withdraw' />
                    <Link icon={ <FaParachuteBox size={20} /> } text={'How To Invest'} path='howToInvest' />
                    <Link icon={ <BsWallet size={20} /> } text={"Company's Wallet"} path='wallets' />
                    <Link icon={ <FiUsers size={20} /> } text={'Referrals'} path='referrals' />
                </div>

                <div className={classes.logout} onClick={logout}>
                    <FiLogOut size={20} /> Logout
                </div>
            </div>
        )
    };

    const MobileSideDrawer = () => {
        return(
            <div className={classes.MobileSideDrawer}>
                <div className={classes.closeSection}  onClick={() => setOpenMobileDrawer(prev => !prev)}/>
                <div className={classes.mainMobileDrawer}>
                    <div className={classes.mobileTop}>
                        <img src={updatedLogo} alt='' onClick={() => navigate('/')} />
                        <div  onClick={() => setOpenMobileDrawer(prev => !prev)}> <FiX /> </div>
                    </div>
                    {props.user ?
                        <div className={classes.mobileProfile}>
                            <img src={props.user?.profilePic ? props.user.profilePic : man} />
                            <p>{props.user.fullname}</p>
                        </div>
                    :
                        <div className={classes.mobileProfile}>
                            <img src={man} />
                            <p>Loading...</p>
                        </div>
                    }
                    <div className={classes.linksContainer}>
                        <Link icon={ <MdOutlineSpaceDashboard size={20} /> } text={'Portfolio'} path='portfolio' />
                        <LinkDropDown icon={ <AiTwotoneBank size={20} /> } text={'All Plans'} />
                        {showPlans &&
                            <div className={classes.plansLink}>
                                <Link icon={ <BsBank size={20} /> } text={'Plan 1'} path='plan1' />
                                <Link icon={ <BsBank size={20} /> } text={'Plan 2'} path='plan2' />
                                <Link icon={ <BsBank size={20} /> } text={'Plan 3'} path='plan3' />
                                <Link icon={ <BsBank size={20} /> } text={'Plan 4'} path='plan4' />
                            </div>
                        }
                        <Link icon={ <BsBriefcase size={20} /> } text={'Deposit'} path='deposit' />
                        <Link icon={ <BsCashStack size={20} /> } text={'Withdraw Funds'} path='withdraw' />
                        <Link icon={ <FaParachuteBox size={20} /> } text={'How To Invest'} path='howToInvest' />
                        <Link icon={ <BsWallet size={20} /> } text={"Company's Wallet"} path='wallets' />
                        <Link icon={ <FiUsers size={20} /> } text={'Referrals'} path='referrals' />
                        <div className={classes.Mobilelogout} onClick={logout} >
                            <FiLogOut size={20} /> Logout
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={classes.container}>
            <SideDrawer />
            
            {props.isProfile ? 
                <div className={classes.isProfile}>
                    {props.children}
                </div>
            :
                <div className={classes.children}>
                    <div className={classes.top}>
                        <h2>{props.active}</h2>
                        <div className={classes.flexItems}>
                            <img src={props.user?.profilePic ? props.user.profilePic : man}  alt='' onClick={() => navigate('/profile')} />
                            <div  onClick={() => setOpenMobileDrawer(prev => !prev)}> <FiMenu /> </div>
                        </div>
                    </div>
                    {props.children}
                </div>
            }
            {openMobileDrawer && <MobileSideDrawer /> }
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return{
        logout: () => dispatch(actions.logout())
    }
}

export default connect(null, mapDispatchToProps)(Hoc)