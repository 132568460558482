import React from 'react';

//styles
import classes from '../banner.module.css';
import { FiDownload, FiUpload } from 'react-icons/fi';

const transactionType = ["Deposit", "Withdrawal"];

function Transactions() {

    function getRandomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function generateRandomEthereumLikeAddress() {
        const characters = "0123456789abcdef";

        const arr = ['1', 'T', '0x'];
        const randomIndex = Math.floor(Math.random() * arr.length);
        let address = arr[randomIndex];
        
        for (let i = 0; i < 40; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          address += characters[randomIndex];
        }
      
        return address;
    }
    

    const Item = () => {

        const randomIndex = Math.floor(Math.random() * transactionType.length);
        const type = transactionType[randomIndex];
        
        let address = generateRandomEthereumLikeAddress();

        if(address.length > 40){
            address = address.substring(0, 35) + "..."
        }

        return(
            <div className={classes.Item}>
                {type === transactionType[0] ? <div className={classes.Item_image}> <FiDownload /> </div> : <div className={classes.Item_image2}> <FiUpload /> </div>}
                <div className={classes.Item_details}>
                    <div className={classes.Item_details_main}>
                        {type === transactionType[0] ? <h3>${getRandomNumber(500, 10000).toLocaleString()} {type}</h3> : <h3>${getRandomNumber(3000, 20000).toLocaleString()} {type}</h3>}
                        <div />
                        <p>{new Date().toDateString().slice(3)}</p>
                    </div>
                    <p className={classes.wallets}>{address}</p>
                </div>
            </div>
        )
    }

    return (
        <div className={classes.transactions}>
            <Item />
            <Item />
            <Item />
            <Item />
            <Item />
        </div>
    )
}

export default Transactions