import React from 'react';

import { AiOutlineBank } from "react-icons/ai";

function Item({title, balance, active, expired, open, plan}) {

    let view = <p className='portActivep'>Active</p>
    if(active === 1){
        view = <p className='Active'>Active</p>
    }
    let view2 = <p className='portActivep'>Expired</p>
    if(expired === 1){
        view2 = <p className='Expired'>Expired</p>
    }
    return (
        <div className='PortFolioItem PFI1' onClick={() => open()} >
            <div>
                <div className='Portf_flex_title'>
                    <p className='PortfolioTitle'>{title}</p>
                    <p className='Port_flex_details'>{plan ? plan : ' '}</p>
                </div>
                
                <p className='PortfolioBalance'>${balance ? balance : 0}</p>
                <div className='PortFolioBottom'>
                    {view}
                    {view2}
                </div>
            </div>
            <div className='PFI_Main'>
                <div className='colored_box' style={{backgroundColor: '#03255C', color: '#ffc823'}}>
                    <AiOutlineBank size={30} color='#ffc823'/>
                </div>
            </div>
        </div>
    );
}

export default Item;