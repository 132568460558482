import React from 'react';

import { BiCalculator, BiGroup, BiUserPlus, BiUser } from 'react-icons/bi';
import { BsFillCalculatorFill } from 'react-icons/bs';

import growth from '../../../Assets/growth.png';

//styles
import classes from './awesomefacts.module.css';

function NewAwesomeFacts() {

    const Item = ({title, amount}) => {
        return(
            <div>
                <p className={classes.title}>{title}</p>
                <h3>{amount}</h3>
            </div>
        )
    }

    return (
        <div className={classes.mainContainer}>
            <div className={classes.container} data-aos="fade-up">
                <div className={classes.rocket_container}>
                    <img src={growth} />
                </div>
                <h2>Awesome Facts</h2>
                <p className={classes.introText}>Here are a couple intresting facts about us</p>
                <div className={classes.main}>
                    <Item title={'Profit Paid'} amount={'$591,734,611'} />
                    <Item title={'Happy Clients'} amount={'79,826'} />
                    <Item title={'Days and Counting'} amount={'3761'} />
                    <Item title={'Total Investors'} amount={'81,031'} />
                </div>
            </div>
        </div>
    )
}

export default NewAwesomeFacts