import React from 'react';

import classes from './balancebox.module.css';

const width = window.innerWidth;

function BalanceBox(props) {
    return (
        <div className={classes.container}>
            <div className={classes.top}>
                <div className={classes.icons}>{props.icon}</div><p>{props.tag}</p>
            </div>
            <div className={classes.bottom}>
                <p className={classes.title}>Amount</p>
                <p className={classes.amount}> ${props.price && props.price.toLocaleString()} </p>
            </div>
            
            {/* <div className='BB_1'>
                <p className='BB_header'>{props.tag}</p>
                <p className='BB_balance'>${props.price ? props.price : 0}</p>
            </div>
            <div className='BB_2'>
                <div className='colored_box' style={{backgroundColor: props.color}}>
                    {box1}
                </div>
            </div> */}
        </div>
    );
}

export default BalanceBox;