import React from 'react';
import './FAQ.css';

function Item({question, answer}) {
    return (
        <div className='FAQ_Item' data-aos="fade-up">
            <p className='FAQ_P1'>{question}</p>
            <p>{answer}</p>
        </div>
    );
}

export default Item;