import React from 'react';

//styles
import classes from './whyvertex.module.css';

///// utilty icons /////////
import icon1 from '../../../Assets/icons/01.png';
import icon2 from '../../../Assets/icons/02.png';
import icon3 from '../../../Assets/icons/03.png';
import icon4 from '../../../Assets/icons/04.png';
import icon5 from '../../../Assets/icons/05.png';
import icon6 from '../../../Assets/icons/06.png';

function WhyVertex() {

    const Item = ({img, title, text}) => {
        return(
            <div className={classes.item} data-aos="fade-up">
                <img src={img} alt='' />
                <h3>{title}</h3>
                <p>{text}</p>
            </div>
        )
    }

    return (
        <div className={classes.container}>
            <h2 data-aos="fade-up">Why Choose Vertex Corp?</h2>
            <div className={classes.main}>
                <Item img={icon1} title={'Fund withdrawal'} text={'Vertexcorp investors are able to withdraw funds and get paid instantly'} />
                <Item img={icon2} title={'Overview'} text={`We are a legal company registered in the United States of America, providing it's services to all countries`} />
                <Item img={icon3} title={'Security'} text={`We are using one of the most sophisticated, encrypted and trusted protection against every possible aspect.`} />
                <Item img={icon4} title={'Our Team'} text={`Our professional and dedicated 24/7 support team will be accessible anytime any assistance is required`} />
                <Item img={icon5} title={'Guarantee'} text={`We provide adequate insurance policy to our premium members`} />
                <Item img={icon6} title={'Diversification'} text={`Our portfolio is diversified and taken care of by the most skilled crypto analysts and traders using automated trading bot and best strategies`} />
            </div>
        </div>
    )
}

export default WhyVertex