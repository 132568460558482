import React, { useState, useRef, useEffect } from 'react';
import emailJs from 'emailjs-com';
import './LoginPage.css';
import {connect} from 'react-redux';
import * as actions from '../../Redux/Actions/Index';
import {LOADING, VERIFY_SCREEN} from '../../Redux/Actions/ActionTypes';
import Loading from '../../Components/Loading/Loading';
import LoginError from './LoginError';
import Captcha from './Captcha';
import PassMismatch from './PassMismatch';

import {FaExclamationTriangle} from 'react-icons/fa';

import classes from './newLoginStyling.module.css'

import countries from './Countries';
import NewHeader from '../../Components/Header/NewHeader/NewHeader';
import { FiCheck } from 'react-icons/fi';

const width = window.innerWidth;


function LoginPage(props) {
    const [signup, setSignup] = useState(false);
    const [verify, setVerify] = useState(false);
    const [verificationCode, setverificationCode] = useState();
    const [modal, setModal] = useState(false);
    const [code, setCode] = useState();
    const [verifyError, setVerifyError] = useState(false)

    const [loginEmail, setLoginEmail] = useState();
    const [loginPassword, setloginPassword] = useState();

    const [SignupFullname, setSignupFullname] = useState();
    const [SignupEmail, setSignupEmail] = useState();
    const [SignupPassword, setSignupPassword] = useState();
    const [ConfirmPassword, setConfirmPassword]  = useState();
    const [ReferallCode, setReferallCode] = useState();
    const [country, setCountry] = useState('');
    const [phone, setPhone] = useState('');

    const [passwordError, setpasswordError] = useState(false);

    useEffect(() => {
        var val = Math.floor(1000 + Math.random() * 9000);
        setverificationCode(val);
        // console.log(val)
    }, []);

    const verifyPass = (e) => {
        if(e.length > 7){
            setpasswordError(true)
        }else{
            setpasswordError(false)
        }
        setSignupPassword(e)
    }

    const submitLogin = () => {
        props.mainLogin(loginEmail, loginPassword);
    }

    const submitSignup = () => {
        props.mainsignup(SignupEmail, SignupPassword, SignupFullname, ReferallCode, country, phone)
    }

    const form = useRef();

    const codeSetter = (e) => {
        setCode(e);
    }

    const EmailVerification = () => {
        if(code == verificationCode){
            submitSignup()
        }else{
            setVerifyError(true)
        }
    }

    const run = () => {
        setModal(false)
        props.history.push('/password_reset')
    }

    const loginForm = (
        <div className={classes.mainForm}>
            <h2>Welcome Back</h2>
            <p className={classes.introText}>Login to your vertex corp account</p>

            {props.errorM ? <p className='Error_message_p'>{props.errorM}</p> : null }
            <div className={classes.inputContainer}>
                <input type="email" placeholder="Email Address" required onChange={(e) => setLoginEmail(e.target.value)} />
                <input type="password" placeholder="Password" required onChange={(e) => setloginPassword(e.target.value)} />
                <div class="pass-link" style={{paddingTop: 10}} onClick={() => setModal(true) }>
                    <a>Forgot password?</a>
                </div>
            </div>
            <button onClick={() => submitLogin()} >Login</button>
            <p className={classes.switchText} onClick={() => setSignup(prev => !prev)}>Don't have an account? <span>Create account</span></p>
        </div>
    )

    const signupForm = (
        <div className={classes.mainForm}>
            <h2>Create Account</h2>
            <p className={classes.introText}>Create your vertex corp account and start earning</p>
            {props.errorM ? <p className='Error_message_p'>{props.errorM}</p> : null }

            <div className={classes.inputContainer}>
                <input type="text" name='user_name' placeholder="Full Name" required onChange={(e) => setSignupFullname(e.target.value)} />
                <input type="email" name='user_email' placeholder="Email Address" required onChange={(e) => setSignupEmail(e.target.value)} />
                <input type="numeric" placeholder="Phone number (optional) " onChange={e => setPhone(e.target.value)} />
                <input type="password" placeholder="Password" required onChange={(e) => verifyPass(e.target.value)} />
                <input type="password" placeholder="Confirm password" required onChange={(e) => setConfirmPassword(e.target.value)} />
                <input type="numeric" placeholder="Referal Code (optional) " onChange={(e) => setReferallCode(e.target.value)} />
                <select name="countries" id="cars" onChange={e => setCountry(e.target.value)} >
                    {
                        countries.map((i) => <option value={i.name} selected={i.code === "US"} key={i.code} >{i.name}</option> )
                    }
                </select>
            </div>
            
            <section className='extra_verificationStuffs'>
                {SignupPassword && <LoginError error={passwordError} /> }
                <Captcha />
            </section>
            <button onClick={() => submitSignup()} >Create account</button>

            <p className={classes.switchText} onClick={() => setSignup(prev => !prev)}>Already have an account? <span>Login</span></p>
        </div>
    )

    let container;

    container = (
        <div className={classes.mainContainer}>
            <div>
                {signup ? signupForm : loginForm}
            </div>
            <div className={classes.bannerImg} style={{backgroundImage: `url(https://res.cloudinary.com/ahumareze/image/upload/v1688825413/03_dhawwp.jpg)`}} />
        </div>
    );

    const logModal = (
        <div className='LoginPage_Modal'>
            <div className='LoginPage_Modal_Main'>
                <div className='FaEx_Container'>
                    <FaExclamationTriangle size={50} color='#03255C'/>
                </div>
                <div className='LM_p_container'>
                    <p>Withdrawal services will be disabled for 24 hours after you make this change to protect your account</p>
                </div>
                <div className='LM_p_bottom_boxes'>
                    <div className='LM_p_bottom_box1' onClick={() => setModal(false)} >Cancel</div>
                    <div style={{width: 20}} />
                    <div className='LM_p_bottom_box2' onClick={() => run()}>Continue</div>
                </div>
            </div>
        </div>
    )

    if(props.verifyScreen){
        container = (
            <div className={classes.verifyScreen}>
                <div className={classes.check}><FiCheck /></div>
                <h3>A verification code has been sent to {props.verifyScreen}</h3>
                <p>Can't find email? Check your spam folder</p>
                <div>
                    <button className={classes.backtologin} onClick={() => {
                        props.closeScreen();
                        setSignup(false)
                    } } >
                        Back to login
                    </button>
                </div>
            </div>
        )
    }

    // if(verify){
    //     container = (
    //         <>
    //             {verificationField}
    //         </>
    //     )
    // }

    return (
        <div className={classes.container}>
            <NewHeader />
            {!props.loading ? container : <Loading /> }
            {modal && logModal}
        </div>
    );
}

const mapStateToProps = state => {
    return{
        loading: state.loading,
        errorM: state.error,
        verifyScreen: state.verifyScreen
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        mainsignup: (email, password, fullname, refCode, country, phone) => dispatch(actions.signup(email, password, fullname, refCode, country, phone)),
        mainLogin: (email, password) => dispatch(actions.login(email, password)),
        setLoading: (value) => dispatch({type: LOADING, value: value}),
        closeScreen: () => dispatch({type: VERIFY_SCREEN, value: false})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);