import React from 'react';
import './Dashboard.css';

import TradingWidget from '../../Components/TradingWidget/TradingWidget';
import BalanceBox from '../../Components/Utilities/BalanceBox';
import DashboardHeader from '../../Components/Utilities/DashboardHeader';
import DepositBox from '../../Components/Utilities/DepositBox';
import SliderWidget from '../../Components/TradingWidget/SliderWidget';

import Countries from '../../Components/Elements/Countries';
import Impressions from '../../Components/Elements/Impressions'; 
import MiniChart from '../../Components/TradingWidget/MiniChart';

//styles
import classes from './styles.module.css';

import { FaDollarSign } from "react-icons/fa";
import { AiOutlineLineChart, AiOutlineBank } from "react-icons/ai";
import { BsCashStack } from "react-icons/bs";

const width = window.innerWidth;

function Component({user, openDrawer, navigate, postImage}) {
    return (
        <div className={classes.container}>
            {/* <DashboardHeader name={user ? user.fullname : ''} profilePic={user ? user.profilePic : null} openDrawer={openDrawer} navigate={navigate} postImage={postImage} /> */}
            <SliderWidget />
            <h3>Dashboard - Plan 1</h3>
            <section className={classes.balanceBoxContainer}>
                <BalanceBox tag='Account Balance' price={user ? user.balance: 0} icon={ <FaDollarSign /> }  />
                <BalanceBox tag='Total Deposit' price={user ? user.deposited : 0} icon={<AiOutlineBank />} />
                <BalanceBox tag='Total Earned' price={user ? user.profit : 0} icon={<AiOutlineLineChart />} />
                <BalanceBox tag='Total Withdrawn' price={user ? user.withdrawn : null} icon={<BsCashStack />} />
            </section>
            <div className={classes.other}>
                <Countries />
                <Impressions />
            </div>
            <section className='DB_tradingWidget'>
                <TradingWidget />
            </section>
        </div>
    );
}

export default Component;