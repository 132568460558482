import React from 'react';

import { FaDollarSign, FaCoins, FaUserFriends } from "react-icons/fa";
import { BsCashStack } from "react-icons/bs";

const roi = require('../../Assets/roi.png');

function BalanceBox({plan1, plan2, plan3, plan4, refBalance, title, showRefBal, color, wit1, wit2, wit3, wit4}) {
    const planone = plan1 ? plan1 : 0;
    const plantwo = plan2 ? plan2 : 0;
    const planthree = plan3 ? plan3 : 0;
    const planfour = plan4 ? plan4 : 0;

    const withone = wit1 ? wit1 : 0;
    const withtwo = wit2 ? wit2 : 0;
    const withthree = wit3 ? wit3 : 0;
    const withfour = wit4 ? wit4 : 0;

    const refBalancee = refBalance ? refBalance : 0;

    const view = (
        <>
        <div className='showref_div'>
            <div>
                <p className='PFI_Title'>Referral Bonus</p>
                <p className='PFI_value'>${refBalancee}</p>
            </div>
            <div className='PFI_Main'>
                <div className='colored_box' style={{backgroundColor: '#03255C'}}>
                    <FaUserFriends size={24} color='#ffc823' />
                </div>
            </div>
        </div>
        <div className='showref_div'>
            <div>
                <p className='PFI_Title'>Total Withdrawn</p>
                <p className='PFI_value'>${withone + withtwo + withthree + withfour}</p>
            </div>
            <div className='PFI_Main'>
                <div className='colored_box' style={{backgroundColor: '#03255C'}}>
                    <BsCashStack size={24} color='#ffc823' />
                </div>
            </div>
        </div>
    </>
    );

    const image = (
        <div className='roi_image'>
            <img src={roi.default}/>
        </div>
    )
    
    return (
        <div className='PortFolioItem'>
            <div className=' PFI1' >
                <div>
                    <p className='PFI_Title'>{title}</p>
                    <p className='PFI_value'>${planone + plantwo + planthree + planfour}</p>
                </div>
                <div className='PFI_Main'>
                    <div className='colored_box' style={{backgroundColor: color, color : '#ffc823'}}>
                        {!showRefBal ?
                        <FaCoins size={24} color='#ffc823' />
                        :
                        <FaDollarSign size={30} color='#ffc823' />
                        }
                    </div>
                </div>
            </div>
            {showRefBal && view}
            {!showRefBal && image}
        </div>
    );
}

export default BalanceBox;