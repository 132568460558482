import React from 'react';

import './HowToInvest.css';
import classes from './styles.module.css';
import { IoChatbubbles } from 'react-icons/io5';

const steps = [
    'Click on Deposit',
    'Choose your investment plan',
    'Choose the coin you are investing in',
    'Fill in your Email and your receiving wallet address',
    'Click on invest',
    'Send your Investment to the company\'s wallet address',
    'Send the investment proof to our live support at the bottom right of the site or any of our authorized agent'
]

function Component(props) {

    const Item = ({text}) => {
        return(
            <div className={classes.item}>
                {text}
            </div>
        )
    }

    return (
        <div className={classes.container}>

            <div className={classes.mainContainer}>
                <div className={classes.top} > <h3>How To Invest</h3> <div className={classes.count}>{steps.length}</div> </div>
                <div className={classes.listContainer}>
                    {steps.map((i, idx) => (
                        <Item text={i} key={idx} />
                    ))}
                </div>
            </div>

            <div className={classes.customerSupport}>
                <div className={classes.image}>
                    <IoChatbubbles />
                </div>
                <div>
                    <h3>Need Further assistance?</h3>
                    <p>if you need further assistance, kindly reach out and one of our customer support agents will get to you.</p>
                </div>
                <a href={`mailto:support@vertexcorpinvestment.com`} target='_blank' ><button>Contact Us</button></a>
            </div>
        </div>
    );
}

export default Component;