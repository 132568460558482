import React from 'react';
import { FiCheckCircle, FiClock, FiX, FiXCircle } from 'react-icons/fi';

//styles
import '../Dashboard/Dashboard.css';
import './Deposit.css';

function OpenDeposit({data, close}) {
    let status;

    if(data.status == 'Approved' || data.status == 'approved'){
        status = (
            <div className='sdm_status'>
                <FiCheckCircle />
                <p>Approved</p>
            </div>
        )
    }else if(data.status == 'Pending' || data.status == 'pending' ){
        status = (
            <div className='sdm_status2'>
                <FiClock />
                <p>Pending</p>
            </div>
        )
    }else if(data.status === 'Declined' || data.status === 'declined'){
        status = (
            <div className='sdm_status3'>
                <FiXCircle />
                <p>Declined</p>
            </div>
        )
    };
    return (
        <div className='ShowDepositScreen'>
            <div className='showDepCancle'>
                <FiX onClick={close} />
            </div>
            <div className='mainShowDeposit'>
                <div className='mainShowDepositTop'>
                    <p className='showDepTitle'>Deposit Details</p>

                    <div className='showDepMain'>
                        <p className='sdm_1'>Amount</p>
                        <p className='sdm_2'>{data.amount}<span>USD</span></p>
                        {status}
                    </div>
                    <p className='sdm_extraStatus'>Crypto has arrived in your Vertexcorp account. View your dashboard for more details.</p>
                </div>

                <div className='sdm_mainDetails'>
                <div className='sdm_item'>
                        <div className='sdm_item_1'>Coin name</div>
                        <div className='sdm_item_2'>{data.name}</div>
                    </div>
                    <div className='sdm_item'>
                        <div className='sdm_item_1'>Network</div>
                        <div className='sdm_item_2'>{data.network}</div>
                    </div>
                    <div className='sdm_item'>
                        <div className='sdm_item_1'>Address</div>
                        <div className='sdm_item_2'>{data.wallet}</div>
                    </div>
                    <div className='sdm_item'>
                        <div className='sdm_item_1'>Plan</div>
                        <div className='sdm_item_2'>{data.plan}</div>
                    </div>
                    <div className='sdm_item'>
                        <div className='sdm_item_1'>Status</div>
                        <div className='sdm_item_2'>{data.status}</div>
                    </div>
                    <div className='sdm_item'>
                        <div className='sdm_item_1'>Date</div>
                        <div className='sdm_item_2'>{data.date}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OpenDeposit;