import React, { useEffect } from 'react';
import './FAQ.css';

import Item from './Item';
import data from './FAQ_data';

//aos
import AOS from 'aos';
import 'aos/dist/aos.css';

import Header from '../../Components/Header/Header';
import NewHeader from '../../Components/Header/NewHeader/NewHeader';

function FAQ(props) {

    useEffect(() => {
        AOS.init()
    }, []);
    
    return (
        <div className='FAQpage'>
            <NewHeader />
            <h2 style={{textAlign: 'center'}} data-aos="fade-up">FAQs</h2>
            <div className='main_faq_container'>
                {data.map((i) => <Item question={i.question} answer={i.answer} />)}
            </div>
        </div>
    );
}

export default FAQ;