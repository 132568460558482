import React from 'react';

//styles
import classes from './depositbox.module.css';

function InputBox({color, name, change, number}) {
    return (
        <div className={classes.input}>
            <input placeholder={name} onChange={(e) => change(e.target.value)} type={number && 'number'} />
        </div>
    );
}

export default InputBox;