import React, { useEffect, useRef, useState } from 'react';
import classes from './wallets.module.css'
import { FiCheck, FiCopy } from 'react-icons/fi';

const width = window.innerWidth;

function Items({title, subTitle, wallet, image}) {
    const elementRef = useRef(null);

    const [copied, setCopied] = useState(false);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(wallet)
          .then(() => {
            setCopied(true);
          })
          .catch((error) => {
            console.error('Error copying text:', error);
          });
    };

    return (
        <div className={classes.item}>
            <div>
                <div className={classes.topItem}> <img src={image} /> {title} {subTitle}</div>
                <div className={classes.content}>
                    <p>{wallet}</p>
                    <div className={classes.copy} onClick={() => handleCopyClick()}>
                        {copied ? <FiCheck size={20} color='green' /> : <FiCopy size={20} />}
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default Items;