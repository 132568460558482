import React from 'react';

//styles
import classes from './team.module.css';

/////// Team photos ////////////
import pic1 from '../../../Assets/Team/01.jpeg';
import pic2 from '../../../Assets/Team/02.png'
import pic3 from '../../../Assets/Team/03.png'
import pic4 from '../../../Assets/Team/04.png'

function Team() {

    const Item = ({img, name, job}) => {
        return(
            <div className={classes.item} style={{backgroundImage: `url(${img})`}} data-aos="fade-up">
                <div className={classes.canva}>
                    <div>
                        <h4>{name}</h4>
                        <p>{job}</p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={classes.container}>
            <h2 data-aos="fade-up">Our Team</h2>
            <p className={classes.introText} data-aos="fade-up">Meet the brains behind Vertex Corp</p>
            <div className={classes.mainContainer}>
                <Item img={pic1} name='Albert Martins' job='CEO and Founder' />
                <Item img={pic2} name='Farnoosh Torabi' job='General Manager' />
                <Item img={pic3} name='Rodney Harris' job='Administrative Assistant' />
                <Item img={pic4} name='Rosario Renderos' job='Consultant' />
            </div>
        </div>
    )
}

export default Team