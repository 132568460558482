import React, { useEffect, useState } from 'react';

//styles
import classes from './newheader.module.css';

//images
import updated_logo from '../../../Assets/updated-logo.png';
import { FiMenu, FiX } from 'react-icons/fi';

function NewHeader() {
    const [showDrawer, setShowDrawer] = useState(false)

    const [isSignedIn, setIsSignedIn] = useState(false)

    useEffect(() => {
        const token = localStorage.getItem('@localToken')
        if(token){
            setIsSignedIn(true)
        }
    }, []);

    const toggleButton = () => {
        setShowDrawer(prev => !prev)
    }

    const sideDrawer = (
        <div className={classes.sideDrawer}>
            <div onClick={() => setShowDrawer(prev => !prev)} />
            <div className={classes.sideDrawerMain}>
                <div className={classes.drawerHeader}>
                    <div className={classes.hamburgerIcon} onClick={() => setShowDrawer(prev => !prev)}>
                        <FiX />
                    </div>
                </div>

                <div className={classes.sideDrawerLinks}>
                    <a href='/' onClick={() =>toggleButton()} ><p>Home</p></a>
                    <a href='/aboutus' onClick={() =>toggleButton()}><p>About</p></a>
                    <a href='/FAQs' onClick={() =>toggleButton()}><p>FAQ</p></a>
                    <a href='/terms-and-conditions' onClick={() =>toggleButton()}><p>Terms</p></a>
                </div>
                {isSignedIn ? 
                    <div className={classes.sideDrawerButtons}>
                        <a href='/signin'><button className={classes.register} onClick={() =>toggleButton()}>View Portfolio</button></a>
                    </div>
                    :
                    <div className={classes.sideDrawerButtons}>
                        <a href='/signin'><button className={classes.login} onClick={() =>toggleButton()}>Login</button></a>
                        <a href='/signin'><button className={classes.register} onClick={() =>toggleButton()}>Get Started</button></a>
                    </div>
                }
            </div>
        </div>
    )

    return (
        <>
            <div className={classes.container}>
                <a href='/'>
                    <div className={classes.top}>
                        <img src={updated_logo} />
                        <h2>Vertex Corp <span>Investment</span></h2>
                    </div>
                </a>
                <div className={classes.links_container}>
                    <div className={classes.main_links_container}>
                        <a href='/aboutus'><p>About</p></a>
                        <a href='/FAQs'><p>FAQ</p></a>
                        <a href='/terms-and-conditions'><p>Terms</p></a>
                    </div>
                    {isSignedIn ?
                        <div className={classes.buttonsContainer}>
                            <a href='/signin'><button className={classes.register}>Portfolio</button></a>
                        </div>
                        :
                        <div className={classes.buttonsContainer}>
                            <a href='/signin'><button className={classes.login}>Login</button></a>
                            <a href='/signin'><button className={classes.register}>Get Started</button></a>
                        </div>
                    }
                    <div className={classes.hamburgerIcon} onClick={() => setShowDrawer(prev => !prev)}>
                        <FiMenu />
                    </div>
                </div>
            </div>
            {showDrawer && sideDrawer}
        </>
    )
}

export default NewHeader