import axios from 'axios';
import React, {useState, useEffect} from 'react';
import Error from '../../Components/Error/Error';
import Loading from '../../Components/Loading/Loading';
import SideDrawer from '../../Components/SideDrawer/SideDrawer';
import ChartWidget from '../../Components/TradingWidget/ChartWidget';
import DashboardHeader from '../../Components/Utilities/DashboardHeader';
import DepositBox from '../../Components/Utilities/DepositBox';

import TradingViewWidget from 'react-tradingview-widget';

import '../Dashboard/Dashboard.css';
import './Deposit.css';
import CardBox from './CardBox';
import OpenDeposit from './OpenDeposit';
import { Hoc } from '../../Components';
import classes from './style.module.css';
import { FiBriefcase } from 'react-icons/fi';
import { BsBriefcase } from 'react-icons/bs';

import empty_coins from '../../Assets/empty_coins.png';

function Dashboard(props) {

    const [user, setUser] = useState();
    const [loading, setLoading] = useState(false);
    const [drawer, setDrawer] = useState(false);
    const [modal, setModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [deposits, setDeposits] = useState([]);
    const [openDeposit, setOpenDeposit] = useState(null);

    const [reload, setReload] = useState(0)

    useEffect(() => {
        getEmail();
    }, [reload]);

    const getEmail = () => {
        const data = localStorage.getItem('@localemail');
        if(data){
            fetchUser(data)
        }
    }

    const fetchUser = (email) => {
        setLoading(true)
        axios.get('https://elite-corp-default-rtdb.firebaseio.com/users.json')
            .then(r => {
                const data = r.data;
                let arr = {};
                Object.keys(data).map((key, i) => {
                    if(data[key].email === email){
                        arr = {...data[key], key: key}
                        console.log(arr)
                        setUser(arr);
                        if(arr.deposits){
                            let depArr = [];
                            Object.keys(arr.deposits).map(i => {
                                depArr.unshift(arr.deposits[i])
                            });
                            setDeposits(depArr);
                            // console.log(depArr)
                        }
                        localStorage.setItem('@key', key)
                        localStorage.setItem('@localUsername', data[key].fullname)
                    }
                });
                setLoading(false)
            })
            .catch(e => {
                setLoading(false);
            })
    }

    const openDrawer = () => {
        setDrawer(prev => !prev)
    }
    const onInvest = (data) => {
        console.log(data)
        if(!data.coinname || !data.amount || !data.wallet || !data.wallet){
            setErrorMessage('Fields Missing')
        }else if(data.amount.includes('$')){
            setErrorMessage('Amount mustn\'t include $')
        }else{
            const datae = {
                pending: {
                    wallteId: data.recievingId,
                    coin: data.coinname,
                    amount: data.amount
                },
                ...user
            };
            axios.put('https://elite-corp-default-rtdb.firebaseio.com/users/' + user.key + '.json', datae )
                .then(r => console.log(r.data))
                .catch(e => {
                    // console.log(typeof(e));
                    // setErrorMessage(e)
                })
        }
        
    }

    const postImage = (img) => {
        const formData = new FormData()
        formData.append('file', img);
        formData.append('upload_preset', 'kqiuojxc');
        setLoading(true)

        axios.post('https://api.cloudinary.com/v1_1/ahumareze/image/upload', formData)
            .then(r => {
                // console.log(r.data.url, user.key)
                postDatabase(r.data.url);
                setUser({...user, profilePic: r.data.url});
                setLoading(false)
            })
            .catch(e => {
                setLoading(false)
            })

    };

    const postDatabase = (url) => {
        const datae = {
            ...user,
            profilePic: url
        }
        axios.put('https://elite-corp-default-rtdb.firebaseio.com/users/' + user.key + '.json', datae )
            .then(r => console.log(r.data))
            .catch(e => {
                // console.log(typeof(e));
                // setErrorMessage(e)
            })
    }

    const postDeposit = (data) => {
        const newData = {
            ...data,
            date: new Date().toDateString()
        }
        setLoading(true)
        axios.post('https://elite-corp-default-rtdb.firebaseio.com/deposit.json', newData)
            .then(r => {
                setLoading(false);
                setModal(true)
            })
            .catch(() => setLoading(false))
    }

    const DepModal = (
        <div className='DepModal'>
            <div className='DepModalMain'>
                <div className='DepModalMainP'>
                    <p>Kindly hold while your investment payment is confirmed</p>
                </div>
                <div className='DepModalMainBtn' onClick={() => {
                    setModal(false)
                    setReload(prev => prev + 1);
                }}>Close</div>
            </div>
        </div>
    );

    let container = (
        <>
            <section className={classes.container}>
                <DepositBox 
                    title='Deposit Details' 
                    boxes={['Coin Name', 'Amount', 'Email', 'Wallet Address']} 
                    onClick={(data) => {
                        postDeposit(data)
                    } }
                    button='Invest'
                    icon={ <BsBriefcase /> }
                />
                <div className='DepositHistory'>
                    <h2>Deposits</h2>
                    <p className='dep_history_extra'>Deposit transactions will appear here after minimum confirmations. Select a transaction to view more details.</p>
                    <div className='DepositHistoryHeader'>
                        <p>Coin</p>
                        <p className='DepoH_amount_title'>Amount</p>
                        <p>Network</p>
                        <p className='DepoH_status'>Plan</p>
                        <p>Status</p>
                    </div>
                    {deposits.length > 0 ? deposits.map(i => (
                        <div className='DepositHistoryItem' onClick={() => setOpenDeposit(i)} >
                            <p>{i.name}</p>
                            <p className='DepoH_amount' >${i.amount}</p>
                            <p>{i.network}</p>
                            <p className='DepoH_status'>{i.plan}</p>
                            <p>{i.status}</p>
                        </div>
                    )) : (
                        <div className={classes.emptyDeposits}>
                            <img src={empty_coins} alt='' />
                            <h3>You haven't made any deposits yet</h3>
                            <p>No deposit has been made on this acccount, visit the above modal and start processing your deposit request</p>
                        </div>
                    )}
                </div>
                {/* <div className='Deposit_header'>
                    <h3>Card Payment</h3>
                    <div className='DP_line' />
                    <p>Fund your account directly with your debit or credit card</p>
                </div>
                <CardBox /> */}
            </section>
            <div style={{width: window.innerWidth - 30, marginRight: 'auto', marginLeft: 'auto'}}>
                <TradingViewWidget symbol="BTCUSDT"/>
            </div>
            {modal && DepModal}
        </>
    )

    const navigate = (destination) => {
        props.history.push(destination)
    }

    return (
        <Hoc user={user} active={'Deposit'} history={props.history}>
            {!loading ? container  : <Loading />}
            {openDeposit && <OpenDeposit data={openDeposit} close={() => setOpenDeposit(null)} /> }
        </Hoc>
    );
}


export default Dashboard;