import React from 'react';

//styles
import classes from './about.module.css';

//images
import picture from '../../../Assets/04.jpg';

function About() {
    return (
        <div className={classes.container}>
            <img src={picture} alt='' data-aos="fade-up"/>
            <div data-aos="fade-up">
                <h2>About us</h2>
                <p className={classes.introText}>We use our master strategy and automated trading software which enables us earn in every entry made not withstanding the market being bullish or bearish.</p>
                <div className={classes.tags}>
                    <div className={classes.tag}>Secure and trusted</div>
                    <div className={classes.tag2}>Fast</div>
                    <div className={classes.tag3}>24/7 support</div>
                </div>
                <div className={classes.mainText}>Vertexcorp investment is an automated online investment platform that is a top secured and profitable option for you. Part of Vertexcorp investment - the team of professional traders focusing mainly on Bitcoin and other cryptocurrencies trading over multiple Exchanges and markets. Headquartered in Massachusetts, Vertexcorp investment is already becoming the Panam's fastest-growing FinTech company. Our name is synonymous with productive and profitable trading solutions where our investors need little to no trading experience at all. With Vertexcorp investment, investors choose one of our three simple investment designs, make a deposit and sit back while our experts take the control.</div>
                <a href='/aboutus'><button>Read more</button></a>
            </div>
        </div>
    )
}

export default About